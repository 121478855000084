import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import type { toMdast } from 'hast-util-to-mdast';
import { last } from 'lodash-es';
import type { ListContent, PhrasingContent, Root, RootContent, TableCell, TableRow } from 'mdast';
import { ColorKey } from 'src/colors';
import { findLanguage } from 'src/editor/editor/plugin/code/codemirror-utils';
import { DEFAULT_CODE_LANGUAGE } from 'src/editor/editor/plugin/code/const';
import { textToSegments } from 'src/editor/utils/editor';
import { fromMarkdown } from 'src/utils/ast-util';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { getDisplay } from 'src/utils/file';
import { v4 as uuidV4 } from 'uuid';
import type { HandleDataType, HandOption, PasteHook } from '../../types';
import { common } from '../common';
import { createBlockFactory } from './create-block';
import { handleBlocks } from './handle-blocks';

export const mdast2Block = (
  props: PasteHook,
  mdast: ReturnType<typeof toMdast>,
  opt?: HandOption
) => {
  const { where, pageBlock } = common(opt);

  if (!pageBlock) return;

  const handleData: HandleDataType = {
    blocks: {},
    blockPos: {},
    files: [],
    images: [],
    start: 0,
  };

  const createBlock = createBlockFactory(pageBlock, handleData);

  const loop = (
    node: RootContent,
    uuid?: string,
    parentId: string = where.parentId,
    isFirstList?: boolean
  ) => {
    if (node.type === 'paragraph') {
      if (!isFirstList || !uuid) {
        uuid = createBlock(BlockType.TEXTAREA, parentId);
      }
      if (node.children.length === 1) {
        // 单行带换行文本特殊处理
        const firstNode = node.children[0];
        if (firstNode?.type === 'text') {
          const allLines = firstNode.value.split('\n');
          allLines.forEach((line, index) => {
            // 第0个在上面
            if (index > 0) {
              uuid = createBlock(BlockType.TEXTAREA, parentId);
            }
            phrasingLoop(
              {
                type: 'text',
                value: line,
              },
              uuid as string
            );
          });
          return;
        }
      }
      node.children.forEach((o) => phrasingLoop(o, uuid as string));
    }

    if (node.type === 'listItem') {
      node.children.forEach((o) => loop(o, uuid as string));
    }

    if (node.type === 'thematicBreak') {
      createBlock(BlockType.DIVIDER, parentId);
    }

    if (node.type === 'heading') {
      uuid = createBlock(BlockType.HEADER, parentId, { level: node.depth });
      node.children.forEach((o) => phrasingLoop(o, uuid as string));
    }

    if (node.type === 'html') {
      // notion 的着重文字特殊处理
      if (/^<aside>/.test(node.value)) {
        const icon = node.value.substring(8, 10) || '';
        uuid = createBlock(BlockType.MARK, parentId, {
          backgroundColor: ColorKey.grey,
          icon: { type: 'emoji', value: icon },
        });
        const markTree = fromMarkdown(node.value.substring(10));
        markTree.children.forEach((o) => loop(o, uuid, parentId));
      } else {
        createBlock(BlockType.TEXTAREA, parentId, {
          segments: [
            {
              type: TextType.TEXT,
              text: node.value,
              enhancer: {},
            },
          ],
        });
      }
    }

    if (node.type === 'blockquote') {
      uuid = createBlock(BlockType.QUOTE, parentId);
      const [firstChild, ...rest] = node.children;
      if (firstChild && firstChild.type === 'paragraph') {
        firstChild.children.forEach((o) => phrasingLoop(o, uuid as string));
        rest.forEach((o) => loop(o, uuid));
      } else {
        node.children.forEach((o) => loop(o, uuid));
      }
    }

    if (node.type === 'list') {
      if (typeof node.start === 'number') {
        handleData.start = node.start;
      }
      node.children.forEach((o) => listLoop(o, uuid || parentId, node.ordered));
    }

    if (node.type === 'code') {
      const lastCodeLanguage = getLastCodeLanguage() ?? DEFAULT_CODE_LANGUAGE;
      createBlock(BlockType.CODE, parentId, {
        format: {
          language: findLanguage(node.lang || lastCodeLanguage || ''),
        },
        segments: [
          {
            type: TextType.TEXT,
            text: node.value,
            enhancer: {},
          },
        ],
      });
    }

    if (node.type === 'table') {
      if (!uuid) {
        const row = node.children[0] as TableRow;
        const properties = row.children.map(() => uuidV4());
        uuid = createBlock(BlockType.TABLE, parentId, {
          format: {
            tableBlockColumnOrder: properties,
            tableBlockRowHeader: true,
          },
        });
        node.children.forEach((o) => tableRowLoop(o, uuid as string, properties));
      }
    }

    if (node.type === 'math') {
      createBlock(BlockType.EQUATION, parentId, {
        segments: [
          {
            type: TextType.TEXT,
            text: node.value,
            enhancer: {},
          },
        ],
      });
    }
  };

  const tableRowLoop = (node: TableRow, parentId: string, properties: string[]) => {
    const uuid = createBlock(BlockType.TABLE_ROW, parentId);

    node.children.forEach((o, i) => tableCellLoop(o, uuid, properties[i] as string));
  };

  const tableCellLoop = (node: TableCell, uuid: string, propertyId: string) => {
    const loop = (_node: TableCell, ordered?: boolean) => {
      _node.children.forEach((o, i) => {
        // @ts-ignore: html 转 markdown 会出现 type 为 listItem 的情况
        if (o.type === 'listItem') {
          const _block = handleData.blocks[uuid];
          if (!_block) return;
          const { collectionProperties = {} } = _block.data;
          const segment: SegmentDTO = {
            type: TextType.TEXT,
            text: `${ordered ? `${i + 1}.` : '-'} `,
            enhancer: {},
          };
          if (collectionProperties) {
            const property = collectionProperties[propertyId];
            if (Array.isArray(property)) {
              segment.text = `\n ${segment.text}`;
            }
            collectionProperties[propertyId] = (property || []).concat(segment);
          } else {
            _block.data.collectionProperties = {
              [propertyId]: [segment],
            };
          }
        }

        // @ts-ignore: html 转 markdown 会出现 type 为 paragraph 或 blockquote 或 list 的情况
        if (['paragraph', 'blockquote', 'list', 'listItem'].includes(o.type)) {
          // @ts-ignore: 同上
          loop(o, o.ordered);
        } else {
          phrasingLoop(o, uuid, undefined, undefined, propertyId);
        }
      });
    };

    loop(node);
  };

  const listLoop = (node: ListContent, parentId: string, ordered?: boolean | null) => {
    let uuid: string;
    if (ordered) {
      uuid = createBlock(BlockType.ORDER_LIST, parentId);
    } else {
      if (node.checked === null) {
        uuid = createBlock(BlockType.LIST, parentId);
      } else {
        uuid = createBlock(BlockType.TODO, parentId, {
          checked: node.checked,
        });
      }
    }
    node.children.forEach((o, i) => loop(o, uuid, uuid, i === 0));
  };

  const phrasingLoop = (
    node: PhrasingContent,
    uuid: string,
    enhancer: SegmentDTO['enhancer'] = {},
    url?: string,
    propertyId?: string
  ) => {
    const block = handleData.blocks[uuid];
    if (!block) return;

    const setSegment = (segment: SegmentDTO) => {
      if (!segment.text) return;

      if (propertyId) {
        const { collectionProperties } = block.data;
        if (collectionProperties) {
          collectionProperties[propertyId] = (collectionProperties[propertyId] || []).concat(
            segment
          );
        } else {
          block.data.collectionProperties = {
            [propertyId]: [segment],
          };
        }
      } else {
        block.data.segments = (block.data.segments || []).concat(segment);
      }
    };

    const getSegment = () => {
      if (propertyId) {
        const { collectionProperties } = block.data;
        if (collectionProperties) {
          return collectionProperties[propertyId];
        }
      } else {
        return block.data.segments;
      }
    };

    if (node.type === 'text') {
      const segment: SegmentDTO = {
        type: TextType.TEXT,
        text: node.value,
        enhancer,
      };

      if (url) {
        segment.type = TextType.URL;
        segment.url = url;
      }

      setSegment(segment);
    }

    if (node.type === 'break') {
      const segment = last(getSegment());
      if (segment?.text) {
        segment.text += '\n';
      }
    }

    if (node.type === 'strong') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, bold: true }, url, propertyId)
      );
    }

    if (node.type === 'emphasis') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, italic: true }, url, propertyId)
      );
    }

    if (node.type === 'delete') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, lineThrough: true }, url, propertyId)
      );
    }

    if (node.type === 'inlineCode') {
      const segment: SegmentDTO = {
        type: TextType.TEXT,
        text: node.value,
        enhancer: { ...enhancer, code: true },
      };
      if (url) {
        segment.type = TextType.URL;
        segment.url = url;
      }
      setSegment(segment);
    }

    if (node.type === 'inlineMath') {
      const segment: SegmentDTO = {
        type: TextType.EQUATION,
        text: node.value,
        enhancer,
      };
      setSegment(segment);
    }

    if (node.type === 'image') {
      if (/^file:\/\//.test(node.url)) return;
      if (/^\.\//.test(node.url)) return;

      const match = node.url.match(/data:(.+);base64/);
      let fileName = node.url;
      let file: File | undefined;
      let extName: string | undefined = 'png';
      if (match) {
        const arr = node.url.split(',');
        const bstr = atob(arr[1] ?? '');
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        for (let i = 0; i < n; i++) {
          u8arr[i] = bstr.charCodeAt(i);
        }
        const type = match[1];
        extName = type?.split('/')[1];
        fileName = `未命名文件.${extName}`;
        file = new File([u8arr], fileName, { type });
        handleData.files.push({ parentId: block.parentId, blockId: block.uuid, file });
        block.type = BlockType.FILE;
        block.data = {
          segments: textToSegments(fileName),
          size: 0,
          extName,
          display: getDisplay(extName),
        };
      } else {
        block.type = BlockType.FILE;
        // 如果图片下载不成功，那么图片大概率在外链上也是用不了的
        // 所以不成功需要显示空块，要让用户知道缺了个图而不是临时的欺骗一下用户，导致隔天就来问缺图问题
        block.data = {
          segments: textToSegments(fileName),
          size: 0,
          extName,
          display: 'image',
          link: node.url,
        };
        handleData.images.push({ parentId: block.parentId, blockId: block.uuid, url: node.url });
      }
    }

    if (node.type === 'link') {
      if (node.children.length) {
        node.children.forEach((o) => phrasingLoop(o, uuid, enhancer, node.url, propertyId));
      } else {
        const segment: SegmentDTO = {
          type: TextType.URL,
          text: node.url,
          url: node.url,
          enhancer,
        };
        setSegment(segment);
      }
    }

    if (node.type === 'html') {
      if (node.value === '<br />') {
        const segment = last(getSegment());
        if (segment?.text) {
          segment.text += '\n';
        }
      } else {
        const segment: SegmentDTO = {
          type: TextType.TEXT,
          text: node.value,
          enhancer: {},
        };
        setSegment(segment);
      }
    }
  };

  if (Array.isArray((mdast as Root).children)) {
    (mdast as Root).children.forEach((o) => loop(o));
  } else {
    loop(mdast as RootContent);
  }

  handleBlocks(props, handleData, opt);
};
